<template>
  <div>
    <heads :states="states" :more="showMore" :msg="title_msg"></heads>
    <div v-if="zkb_pro==1">
      <!-- zkb -->
      <van-list
        v-if="isshow"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        style="margin-top: 10px"
        @load="onLoad"
      >
        <div v-for="(item,i) in lists" :key="i" class="div_evaluation_box">
          <div class="div_evaluation_item">
            <div class="div_evaluation_top">
              <div class="div_evaluation_left">
                <p class="p_title">
                  <span class="red_line"></span>
                  <span>{{ item.exam_name }}</span></p>
                <p class="p_content">共{{ item.number }}题，答对<span style="color: #ee2e2e">{{ item.correct_sum }}</span>题</p>
                <p class="p_content">评测时间：{{ item.day }}</p>
              </div>
              <div class="div_evluation_right">
                <div :id="'myChart'+i" class="evaluation_chart"></div>
              </div>

            </div>
            <div class="div_evaluation_bottom">
              <div v-if="item.answer_url && item.answer_url.length > 0 " class="btn_red" @click="go_zkb_url(1,item.answer_url)">查看解析</div>
              <div v-if="item.afresh_url && item.afresh_url.length > 0" class="btn_white" @click="go_zkb_url(2,item.afresh_url)">重新评测</div>
            </div>
            <p class="p_subscript" :style="'background:'+subScriptList[item.review_type-1].color">{{ subScriptList[item.review_type-1].content }}</p>
          </div>
        </div>
      </van-list>
      <div v-else class="kongzt">
        <img src="@/static/images/my_index/kong.png" alt>
        <p>您还没有评测过哦~</p>
      </div>
    </div>
    <div v-else>
      <!-- zl -->
      <van-list
        v-if="isshow"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        style="margin-top: 10px"
        @load="onLoad"
      >
        <div v-for="(item,i) in lists" :key="i" class="div_evaluation_box">
          <div class="div_evaluation_item">
            <div class="div_evaluation_top">
              <div class="div_evaluation_left">
                <p class="p_title">
                  <span class="red_line"></span>
                  <span>{{ item.name }}</span></p>
                <p class="p_content">共{{ item.exercise_num }}题，答对<span style="color: #ee2e2e">{{ item.correct_num }}</span>题</p>
                <p class="p_content">评测时间：{{ item.create_time }}</p>
              </div>
              <div class="div_evluation_right">
                <div :id="'myChart'+i" class="evaluation_chart"></div>
              </div>

            </div>
            <div class="div_evaluation_bottom">
              <div v-if="item.answer_url && item.answer_url.length > 0" class="btn_red" @click="go_url(1,item)">查看解析</div>
              <div v-show="item.cert_pay_status == 2" class="btn_white" @click="go_url(2,item.buy_url)">领取证书</div>
              <div v-if="item.afresh_url" class="btn_white" @click="go_url(3,item)">重新评测</div>
              <div v-show="item.cert_pay_status == 1 && item.cert_url" class="btn_white" @click="go_url(2,item.cert_url)">查看证书</div>
              <div v-if="item.express_no && item.express_no.length > 0" class="btn_white" @click="checkTheExpress(item)">查看物流</div>
            </div>
            <p class="p_subscript" :style="'background:'+subScriptList[item.answer_type-1].color">{{ subScriptList[item.answer_type-1].content }}</p>
          </div>
        </div>
      </van-list>
      <div v-else class="kongzt">
        <img src="@/static/images/my_index/kong.png" alt>
        <p>您还没有评测过哦~</p>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import tool from '@/utils/tools'
import { getUserReviewListNew, getUserReviewListByZKBPRO } from '@/api/my_user.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      states: 1,
      isShow: false,
      subScriptList: [{
        color: '#81B337',
        content: '随堂练习'
      }, {
        content: '固定时间',
        color: '#3BA4E6'
      }, {
        color: '#ee2e2e',
        content: '竞赛模式'
      }, {
        color: '#EE9D2E',
        content: '随到随考'
      }, {
        content: '随堂评测',
        color: '#30BCBC'
      }],
      title_msg: '我的评测',
      limit: 10,
      page: 1,
      lists: [],
      isshow: true,
      showMore: true,
      loading: false,
      finished: false,
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: ''
    }
  },
  beforeMount() {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function(e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  mounted() {
    if (tool.newIsZkb()) {
      this.zkb_pro = 1
      this.showMore = false
    }
    this.get_lists()
  },
  methods: {
    checkTheExpress(item) {
      this.$router.push({ path: 'express' })
    },
    makeEchart(val, index) {
      var value = val.score
      var maxValue = 100// 进度条最大值
      var myChart = this.$echarts.init(document.getElementById('myChart' + index))
      myChart.resize({
        width: 50,
        height: 50
      })
      var option = {
        backgroundColor: '#F0F0F0',
        // 环形中间文字
        graphic: [
          // 第一行文字
          // 内容 + 位置
          {
            type: 'text',
            left: value === 100 ? '10%' : 'center',
            top: '30%',
            style: {
              // value当前进度
              text: value + ' ',
              textAlign: 'left',
              fill: '#ee2e2e',
              fontWeight: 500,
              fontSize: 20
            }
          },
          {
            type: 'text',
            left: value === 100 || value < 10 ? '70%' : '68%',
            top: '40%',
            style: {
              // value当前进度
              text: '分',
              textAlign: 'center',
              fill: '#ee2e2e',
              fontWeight: 500,
              fontSize: 12
            }
          }
        ],
        series: [
          {
            type: 'pie',
            symbol: 'circle', // 变为实心圆
            radius: ['100%', '88%'], // ['外圆大小', '内圆大小']
            center: ['50%', '50%'], // 圆心位置['左右'， '上下']
            hoverAnimation: false, // 取消鼠标悬停动画
            animationEasing: 'cubicOut', // 设置动画缓动效果
            // 取消显示饼图自带数据线条
            labelLine: {
              normal: {
                show: false
              }
            },
            // 增加阴影效果
            itemStyle: {
              normal: {
                shadowBlur: 100,
                shadowColor: '#ffffff',
                backgroundColor: '#ee2e2e'

              }
            },
            data: [
            // value当前进度 + 颜色
              {
                value: value,
                itemStyle: {
                  normal: {
                    color: '#ee2e2e',
                    backgroundColor: '#ee2e2e'

                  }
                }
              },
              // (maxValue进度条最大值 - value当前进度) + 颜色
              {
                value: maxValue - value,
                itemStyle: {
                  normal: {
                    color: '#F0F0F0',
                    backgroundColor: '#ee2e2e'

                  }
                }
              }
            ]
          }
        ]
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
      window.onresize = myChart.resize
      this.isShow = true
    },
    GetUrlParam(url, paraName) {
      var arrObj = url.split('?')
      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split('&')
        var arr

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split('=')

          if (arr != null && arr[0] == paraName) {
            return arr[1]
          }
        }
        return ''
      } else {
        return ''
      }
    },
    go_url(val, item) {
      switch (val) {
        // 解析
        case 1:
          location.href = item.answer_url
          break
        // 证书
        case 2:
          window.open(item)
          break
        // 重新评测
        case 3:
          location.href = item.afresh_url
          break
      }
    },
    // 注考帮的点击: 1、查看解析  2、重新评测
    go_zkb_url(type, url) {
      // 接口返回的链接：1、域名更换 2、/edu/zkbpro/ 替换为 /
      var reset_url = url.replace('https://m.zhulong.com', window.location.origin)
      reset_url = reset_url.replace('/edu/zkbpro/', '/')
      console.log(reset_url, 'url1url1url1')
      if (type === 1) {
        // 1、查看解析
        window.location.href = reset_url
      } else if (type === 2) {
        // 2、重新评测
        window.location.href = reset_url
      }
    },
    onLoad() {
      this.get_lists()
    },
    get_lists() {
      this.loading = true
      var params = {
        page: this.page,
        limit: this.limit,
        zkb_pro: this.zkb_pro
      }
      if (this.zkb_pro == 1) {
        // zkb pro
        getUserReviewListByZKBPRO(params).then(res => {
          const result = res.result ? res.result : {}
          if (result.list) {
            result.list.forEach(e => {
              e.exam_name = e.name
              e.number = e.exercise_num
              e.correct_sum = e.correct_num
              e.day = e.create_time
              e.review_type = e.answer_type
              e.result_url = e.answer_url
              e.url = e.afresh_url
            })
          }
          this.getRequestResult(result)
        }).catch(() => {
          this.finished = true
          this.loading = false
        })
      } else {
        getUserReviewListNew(params).then(res => {
          const result = res.result ? res.result : {}
          this.getRequestResult(result)
        }).catch(() => {
          this.finished = true
          this.loading = false
        })
      }
    },
    // 获取到数据后的解析
    getRequestResult(result) {
      this.loading = false
      // 总数
      const count = result ? result.count : 0
      const list = result ? result.list : []
      this.lists = this.lists.concat(list)
      console.log(this.lists.length)
      if (this.lists.length > 0) {
        this.isshow = true
      } else {
        this.isshow = false
      }
      if (this.lists.length < count) {
        this.page++
        console.log('还有的啊 继续加加加啊 ' + this.page)
      } else {
        // 没有更多了
        this.finished = true
      }
      for (let i = 0; i < this.lists.length; i++) {
        this.$nextTick(() => {
          this.makeEchart(this.lists[i], i)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .div_evaluation_box{
    margin: 0 28px 30px 28px ;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.14);
    font-family: Roboto;
    position: relative;
    .p_subscript{
      position: absolute;
      right: 0;
      top: 0;
      width: 130px;
      height: 34px;
      border-radius: 0px 10px 0px 20px;
      color: rgba(16, 16, 16, 1);
      text-align: center;
      color: white;
      font-size: 20px;
      font-family: HarmonyOS_Sans_SC-regular;
    }
    .div_evaluation_item{
      width: calc(100% - 20px);
      padding: 40px 20px 20px 0;
      .div_evaluation_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .div_evaluation_left{
          width: calc(100% - 140px);
          .p_title{
            color: rgba(51, 51, 51, 1);
            font-size: 32px;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-align: left;
            font-family: HarmonyOS_Sans_SC-medium;
            .red_line{
              display: inline-block;
              width: 4.4px;
              background: #ee2e2e;
              margin-right: 32px;
              height: 22px;
            }
          }
          .p_content{
            margin-left: 32px;
            color:#999999;
            font-size: 24px;
            text-align: left;
            padding-top: 8px;
            font-family: HarmonyOS_Sans_SC-medium;
          }

        }
        .div_evluation_right{
          width: 120px;
          height: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          .evaluation_chart {
            border-radius: 50%;
            overflow: hidden;
          }
        }
      }
      .div_evaluation_bottom{
        display: flex;
        justify-content: start;
        align-items: center;
        border-top: 1px solid #E9E9E9;
        margin-top: 20px;
        padding-top: 16px;
        margin-left: 32px;

        .btn_red{
          height: 46px;
          line-height: 47px;
          padding: 0 26px;
          border-radius: 26px;
          background-color: rgba(238, 46, 46, 1);
          color: white;
          font-size: 20px;
          text-align: center;
          font-family: Roboto;
          margin-right: 12px;
        }
        .btn_white{
          height: 46px;
          line-height: 47px;
          padding: 0 26px;
          margin-right: 12px;
          border-radius: 26px;
          background-color: white;
          color: #ee2e2e;
          font-size: 20px;
          text-align: center;
          font-family: Roboto;
          border: 1px solid #ee2e2e;
        }
      }
    }
  }

.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}
</style>
